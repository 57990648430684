<template>
    <div style="padding-top:2px" >

    <div style="   float: left;"> 
        <img   :src="logoPath"   style="height:100px"  alt="logo">    
    </div>
        <div style="float: right; font-size:20px"> 
                <v-row style="margin:2px;height:20px;">{{client.nomNaissance}} {{client.prenoms[0]}} {{client.prenoms[1]}} {{client.prenoms[2]}} {{client.prenoms[3]}} {{client.prenoms[4]}} {{client.prenoms[5]}} </v-row>
                <v-row style="margin:2px;height:20px;"> {{calculateAge(client.naissance,dateetude)}} ans, né(e) le {{ formatedDate(client.naissance) }} </v-row>
                <v-row style="margin:2px;height:20px;"> Date de l'étude : {{ formatedDate(dateetude)}} </v-row>
                <v-row style="margin:2px;height:20px;">  J.P. :<span :class="[ jourPersonnel.couleur ]">&nbsp; {{jourPersonnel.valeur}} &nbsp; </span>- M.P. : <span :class="[ moisPersonnel.couleur ]">&nbsp; {{moisPersonnel.valeur}} &nbsp; </span> - A.P. : <p :class="[ anneePersonnelle.couleur ]"> &nbsp; {{anneePersonnelle.valeur}}&nbsp;  </p> </v-row>
        </div>
</div>
</template> 
<script setup>

</script>
    <script>
    export default {
    props: ['logoPath', 'dateetude', 'client','jourPersonnel','moisPersonnel','anneePersonnelle', ],

methods: {
    formatedDate(date) {
        return new Date(date).toLocaleDateString('fr-FR');
    },
    calculateAge(birthday,dateEtude) { // birthday is a date
    var ageDifMs = (new Date(dateEtude)) - (new Date(birthday).getTime());
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
}
}
</script>