
<template>
    <v-container style="padding: 0px;">
        <v-dialog width="800px"  v-model="isshow">
          <v-card class="rounded-xl pa-0 ma-2"   color="#05445E">
    
            <v-img
            src="./fond_vague_long.png"
            gradient="to right, #05445EDD, #05445EFF"
          
            height="50px"
            cover
          >
            <v-card-title  class="text-white text-body-1 "   style="color:black;"
           > {{outil}} - {{itemLabel}} - Nombre {{value}}</v-card-title>
          </v-img>
          
          <v-card-text style="background-color: white;"> 
       <v-row> 
        <v-col cols="4" v-if="user.role=='Administrateur'  || user.role=='Adherent avance' || user.role=='Adherent'" > <v-btn color="blue darken-1" @click="addGeneral" text >Dictionnaire général</v-btn></v-col>
        <v-col cols="4"> <v-btn color="blue darken-1" @click="addPerso" text >Dictionnaire personnel</v-btn></v-col>
        <v-col cols="4" v-if="user.role=='Administrateur'  || user.role=='Adherent avance' "> <v-btn color="blue darken-1" @click="addAvance" text >Dictionnaire avancé</v-btn></v-col>
        
      </v-row>
      <v-row> <v-col cols="12">  <QuillEditor   style="height: 250px"  theme="snow"  contentType="html"
        :options="editorOption" label="Commentaires" v-model:content="commentaire"/> </v-col> </v-row>
          </v-card-text>
              <v-card-actions   style="background-color: white;">
                <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="save">Valider</v-btn>
                 </v-card-actions>
          </v-card>
          </v-dialog>
    </v-container>
    </template>

    <script setup>

</script>
    <script>
  
import { QuillEditor,Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import commentaireService from '../services/commentaireService';
  import dictionnaireService from '../services/dictionnaireService';
  import dictionnaireCommunService from '../services/dictionnaireCommunService';
  import dictionnaireAvanceService from '../services/dictionnaireAvanceService';
  import configService from '../services/configService';
  import { store } from '../store.js'
  Quill.register("modules/imageCompressor", imageCompressor);
      export default {
        props: ['show','etudeId','clientId','outil','item','value','variant'],
        components: {
    QuillEditor
  }, computed: {
      user() {
        return store.user;
      }},
        data () {
          return {
            exemple:"exemple",
            search: '',
            itemLabel:'',
            isshow:false,
            commentaire:'',
            editorOption: configService.getQuillConfig()
          }
        },
        methods: {
          addPerso(){
            dictionnaireService.search(this.outil, this.item,this.value ).then((response) =>
             {
              if(response.data && response.data.length>0)
              this.commentaire+=response.data[0].content;})},
          addAvance(){dictionnaireAvanceService.search(this.outil, this.item,this.value ).then((response) =>
             {
              if(response.data && response.data.length>0)
              this.commentaire+=response.data[0].content;})},
          addGeneral(){  
   dictionnaireCommunService .search(this.outil, this.item,this.value ).then((response) =>
             {
              if(response.data && response.data.length>0)
              this.commentaire+=response.data[0].content;})},
    
          close(){
            this.isshow=false
            this.$emit("closed");
          },
          save(){
            this.isshow=false
            commentaireService.save(this.clientId, this.etudeId, this.outil, this.item,this.value, this.commentaire,this.variant).then(() => {
       
              this.$emit("updated");
            });
           
            this.$emit("closed");
          },
        },
        watch  : {
          show(val) {
            this.commentaire='';
            this.itemLabel=this.item;
            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value,this.variant ).then((response) => {
              if(response.data && response.data.length>0)
              this.commentaire=response.data[0].content;
            else
            this.commentaire='';
            });
            dictionnaireCommunService .search(this.outil, this.item,this.value ).then((response) =>
            {
              this.itemLabel = response.data[0].itemLabel;

            })
            this.isshow=val

    },
   
  },
      }
    </script>