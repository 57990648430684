
<template>
    <v-container>

  
<div  width="100%" style="    box-sizing: initial;">
<div style="position:relative; width: 1100px; ">
	<div style="float:left;width: 100px;    ">
		<div style="width: 99px; height:40px; border: 1px solid black;font-size:15px;
		border-top-width: 2px;
		border-left-width: 2px;
		;line-height: 1;
		">
			<p align="center" >MOIS</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Janvier</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Février</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Mars</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Avril</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Mai</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Juin</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Juillet</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Août</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Septembre</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Octobre</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;">
			<p align="center" style="margin-top: 6px;">Novembre</p>
		</div>
				<div style="width: 99px; height: 50px; border: 1px solid black;	border-left-width: 2px;border-bottom-width:2px">
			<p align="center" style="margin-top: 6px;">Décembre</p>
		</div>
			</div>
	<div style="float:left;width: 100px;">

    <div style="width: 100px; height:40px; border: 1px solid black ;font-size:15px;line-height: 1;
		border-top-width: 2px;">
			<p align="center">MOIS PERSONNEL</p>
		</div>
    
    <div  align="center"  :style="styleMois(n)" v-for="n in agendaMois" :key="n"
    :value="n"> 
	
    <p :class="[ showCom?'linklookalike':'',n.couleur, 'planMoisSmall']" style="margin: 15px;" v-on:click="showComModal(n.detail,n.valeur)" >{{ n.valeur }}</p>
  </div >

			</div>
	<div style="float:left;width: 100px;">
			
    <div style="width: 100px; height: 40px; border: 1px solid black;
		border-top-width: 2px; ;line-height: 1;">
			<p align="center" style="margin:0;padding:0;">ANNEE<br>{{    (new Date(dateetude)).getFullYear()  }}</p>
    </div>
    <div style="width: 100px; height: 622px; border: 1px solid black;border-bottom-width:2px">
			<p align="center" style="margin-top: 250px;">
        <span style=" font-weight: bold;"><div v-on:click="showComModal('Année personnelle',anneePersonnelle.valeur)"  :class="[  showCom?'linklookalike':'',anneePersonnelle.couleur, 'planCellSmall']" style="margin: 15px;"  >{{ anneePersonnelle.valeur }}</div></span>
			</p>
    </div>
  </div>
	<div style="float:left;width: 100px;">
		<div style="width: 100px; height: 40px; border: 1px solid black;font-size:15px;
		border-top-width: 2px;;line-height: 1;">
			<p align="center">CYCLES</p>
		</div>
    <div  :style="getStyle(cycle)" v-for="cycle in plans"  :key="cycle"
    :value="cycle">
   <p align="center" :style="getStyleNumber(cycle)"  v-on:click="showComModal(cycle.item3.detail,getValeur(cycle.item3))"   :class="[ showCom?'linklookalike':'',getCouleur(cycle.item3), 'planCellSmall']"   > {{getValeur(cycle.item3)}}</p>
 </div>

			</div>
	<div style="float:left;width: 100px;">
		<div style="width: 100px; height:40px; border: 1px solid black;font-size:15px;
		border-top-width: 2px;border-right-width: 2px;;line-height: 1;">
			<p align="center">ACTIONS</p>
		</div>
    <div  :style="getStyleAction(cycle)" v-for="cycle in plansCycles"  :key="cycle"
    :value="cycle">
   <p align="center" v-on:click="showComModal(cycle.detail,cycle.valeur)" :class="[ showCom?'linklookalike':'',cycle.couleur, 'planCellSmall']"  style="margin-top: 70px;" > {{cycle.valeur}}</p>
 </div>
			</div>
	<div style="float:left;width: 600px;" v-if="showComment">
		<div style="width: 600px; height: 40px; border: 1px solid black;font-size:15px;
		border-top-width: 2px;;line-height: 1;">
			<p align="center">COMMENTAIRE</p>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_1" v-on:click="showComModal('Commentaire','1')"  :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="1"  :clientId="clientId"></FlatCommentaireComponent></span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_2" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','2')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="2"  :clientId="clientId"></FlatCommentaireComponent></span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_3" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','3')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="3"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_4" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','4')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="4"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_5" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','5')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent :timeStamp="timeStamp"  :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="5"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_6" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','6')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent   :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="6"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_7" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','7')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent :timeStamp="timeStamp"  :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="7"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_8" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','8')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="8"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_9" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','9')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="9"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_10" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','10')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="10"  :clientId="clientId"></FlatCommentaireComponent></span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_11" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','11')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="11"  :clientId="clientId"></FlatCommentaireComponent></span>
			
					</div>
		</div>
				<div style="width: 600px; height: 50px; border: 1px solid black">
			<div style="padding: 2px;">
		
			<span id="inplace_plan_annee_commentaire_12" title="Cliquer pour modifier le texte" v-on:click="showComModal('Commentaire','12')" :class="[ showCom?'linklookalike':'']">
				<FlatCommentaireComponent  :timeStamp="timeStamp" :etudeId="etudeId"  outil="Plan année" item="Commentaire" value="12"  :clientId="clientId"></FlatCommentaireComponent>
			</span>
			
					</div>
		</div>
			</div>
	<div style="clear:both"></div>
</div>
  
</div>
<CommentaireSaisieComponent v-on:updated='this.$emit("commentaireUpdated");this.refresh();	timeStamp= new Date().getTime();' :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

    </v-container>
    </template>

    <script setup>

</script>
    <script>
  import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
  import FlatCommentaireComponent from '../components/FlatCommentaireComponent.vue';

  import clientService from "../services/clientService";
      export default {
		components: {CommentaireSaisieComponent,FlatCommentaireComponent},
        props: ['clientId', 'dateetude','showComment','showCom','etudeId'],

  watch: {
    clientId(val) {
      if(val)
      this.refresh();
    },
    dateetude(val) {
      if(val)
      this.refresh();
    },
  },
   mounted() {
   this.refresh()

   
          },
        data () {
          return {
			timeStamp: new Date().getTime(),
            plans: [],
            plansCycles: [],
            anneePersonnelle:"",
            agendaMoisJour:[],
            agendaMois:[],
            exemple:"exemple",
            search: '',
			showCommentaireSaisieComponent: false,
      outil:"Plan année",
      item:"Cycle 1",
      crtValue:"1",
    
          }
        },
        methods: {
			styleMois(mois)
			{
				if(mois.detail=="décembre")return "width: 100px; height: 50px; border: 1px solid black; border-bottom-width: 2px";
				return "width: 100px; height: 50px; border: 1px solid black";
			},
			showComModal(itx, value){
			
      if(this.showCom){
      this.item = itx;
      this.crtValue = value;
      this.showCommentaireSaisieComponent = this.showCom;
    }  },

		getStyleAction(anAction)
	{
		if(anAction.detail == "Action 4")	return "width: 100px;height: 154px;  border: 1px solid black;border-right-width: 2px;border-bottom-width: 2px";
		return "width: 100px;height: 154px;  border: 1px solid black;border-right-width: 2px";
	},
			getStyle(aCycle)
          {
			var borneFin = aCycle.item2;
			var bornDebut = aCycle.item1;
			if(bornDebut < 10) bornDebut = 1;
			if(borneFin > 355) borneFin = 365;
			var cpt = borneFin-bornDebut;
		//91,25
			var complete="";
			if(borneFin > 365) complete="border-bottom-width: 2px;"
			if(cpt>10) return  'width: 100px;height: '+ 1.698*cpt+'px;  border: 1px solid black ;  text-align: center;'+complete ;
			return  'width: 100px;height: 0px; visibility:collapse;  text-align: center;'+complete;
		
		},
		getStyleNumber(aCycle)
          {

			var cpt = aCycle.item2-aCycle.item1;
		//91,25
			if((cpt*0.8)<25) return 'top: 0px;   position: relative;   '
			if(cpt>10) return  'top: '+ 0.8*cpt+'px;   position: relative;   '
			return ''
		
		},
          getCouleur(aCycle)
          {return aCycle.couleur},
          getValeur(aCycle)
          {return aCycle.valeur},
          formatedDate(unedate)
          { 
            if(unedate) return (new Date(unedate)).toISOString().substring(0,10)
              else return ""
          },
        refresh() {

          clientService.getPlanActionAnnee(this.clientId, this.dateetude+"-01-01").then((resutlt) => {
              this.plansCycles = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService.getCycleAnnee(this.clientId, this.dateetude+"-01-01").then((resutlt) => {
              this.plans = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })

 
    clientService. getAnneePersonnelle(this.clientId,this.dateetude+"-01-01").then((resutlt) => {
      this.anneePersonnelle = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService.getAgendaMois(this.clientId,this.dateetude+"-01-01").then((resutlt) => {
              this.agendaMois = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService. getMoisPersonnel(this.clientId,this.dateetude+"-01-01").then((resutlt) => {
              this.moisPersonnel = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService. getJourPersonnel(this.clientId,this.dateetude+"-01-01").then((resutlt) => {
              this.jourPersonnel = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })

        },
      }
      }
    </script>