
<template>
    <div >
    <div id="calendrier" class="landscape-content ">
      
      
      <table  style=" background-color: white;
      margin: auto;
      width: 90%;
     border-style: solid;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    border-width: 1px;"
    v-if="loaded">
      <tbody><tr style=" border-style: solid;">
        <td colspan="12" style=" border-style: solid; border-width: 1px;"  align="center"><span style=" font-weight: bold;"><div :class="[ anneePersonnelle.couleur]"  >{{    (new Date(dateetude)).getFullYear()  }} - Année personnelle : {{ anneePersonnelle.valeur }}</div></span></td>
      </tr>
      <tr style=" border-style: solid;">
        <td  style=" border-style: solid; border-width: 1px;"  align="center" v-for="n in agendaMois" :key="n"
        :value="n">  <p class="text-capitalize">{{ n.detail }}</p><div :class="[ n.couleur]" >M.P.  {{ n.valeur }}</div></td >

          </tr>
        <tr v-for="idxj in 31"  :key="idxj"
        :value="idxj" style=" border-style: solid; border-width: 1px;">
            <td align="left"  style="font-size: 10px;
            border-style: solid; border-width: 1px; 
            line-height: 1.231;" v-for="idx in 12"  :key="idx"
            :value="idx">

            &nbsp;{{ agendaMoisJour[idx][idxj].detail }} 
            <a  :class="[ agendaMoisJour[idx][idxj].couleur]">
              &nbsp; &nbsp;{{ agendaMoisJour[idx][idxj].valeur }}
            </a>  
        </td>
      </tr>
    </tbody></table>
  </div>
  <CommentaireSaisieComponent :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:updated='this.$emit("commentaireUpdated")' v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

</div>
    </template>

    <script setup>

</script>
    <script>
  import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";

  import clientService from "../services/clientService";
      export default {
        components: {CommentaireSaisieComponent},
        props: ['clientId', 'dateetude','showCom','etudeId','commentaires'],

watch  : {
    clientId(val) {
      if(val)
      this.refresh();
    },
    dateetude(val) {
      if(val)
      this.refresh();
    },
  },
   mounted() {
   this.refresh()

   
          },
        data () {
          return {
            loaded: false,
            plan9ans: [],
            anneePersonnelle:"",
            jourPersonnel:"",
            moisPersonnel:"",
            agendaMoisJour: [],
            agendaMois:[],
            moisCycle:[],
            exemple:"exemple",
            search: '',
            showCommentaireSaisieComponent: false,
      outil:"Calendrier",
      item:"Jour personnel",
      crtValue:"1",
    
          }
        },
        methods: {
          showComModal(itx, value){
      if(this.showCom){
      this.item = itx;
      this.crtValue = value;
      this.showCommentaireSaisieComponent = this.showCom;
    }  },

          formatedDate(unedate)
          { 
            if(unedate) return (new Date(unedate)).toISOString().substring(0,10)
              else return ""
          },
        refresh() {
        
    
      
    clientService.getAgendaMois(this.clientId,this.dateetude+"-01-01").then((resutlt) => {
              this.agendaMois = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService. getAgendaMoisJour(this.clientId,this.dateetude+"-01-01").then((resutlt) => {
              this.agendaMoisJour = resutlt.data
              this.loaded=true;
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService. getAnneePersonnelle(this.clientId,this.dateetude+"-01-01").then((resutlt) => {
      this.anneePersonnelle = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
   
        },
      }
      }
    </script>