
 <template>
           <v-container style="padding-bottom: 0px;">
        
                  <v-card      variant="flat" >
                    <v-card-title class="themeTitle">Champ des expériences</v-card-title>
      <v-card-text class="justify-center" > 
        <div > 
          <div style="    shape-outside: content-box;    shape-margin: 30px;    width: 350px;    float: left;    height: 200px;" > 
         <div style="  width: 320px;   " > 
          <v-row  align="center">
           <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[1].couleur]"  v-on:click="showComModal('1',champExperiences[1].valeur)"><div class="experienceCellIndice eciL1  eciC1">1</div>{{ champExperiences[1].valeur }}</v-col>
           <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[2].couleur]" v-on:click="showComModal('2',champExperiences[2].valeur)"><div class="experienceCellIndice eciL1 eciC2">2</div>{{ champExperiences[2].valeur }}</v-col>
           <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[3].couleur]"  v-on:click="showComModal('3',champExperiences[3].valeur)"><div class="experienceCellIndice eciL1 eciC3 ">3</div>{{ champExperiences[3].valeur }}</v-col></v-row>
            <v-row align="center"
            justify="center">
              <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[4].couleur]" v-on:click="showComModal('4',champExperiences[4].valeur)"><div class="experienceCellIndice eciL2  eciC1">4</div>{{ champExperiences[4].valeur }}</v-col>
              <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[5].couleur]" v-on:click="showComModal('5',champExperiences[5].valeur)" ><div class="experienceCellIndice eciL2  eciC2">5</div>{{ champExperiences[5].valeur }}</v-col>
              <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[6].couleur]"  v-on:click="showComModal('6',champExperiences[6].valeur)"><div class="experienceCellIndice eciL2  eciC3">6</div>{{ champExperiences[6].valeur }}</v-col>
            </v-row>
                <v-row align="center"
                justify="center">
                  <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[7].couleur]" v-on:click="showComModal('7',champExperiences[7].valeur)" ><div class="experienceCellIndice eciL3  eciC1">7</div>{{ champExperiences[7].valeur }}</v-col>
                  <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[8].couleur]" v-on:click="showComModal('8',champExperiences[8].valeur)" ><div class="experienceCellIndice eciL3  eciC2">8</div>{{ champExperiences[8].valeur }}</v-col>
                  <v-col :class="['experienceCell', showCom?'linklookalike':'',champExperiences[9].couleur]"  v-on:click="showComModal('9',champExperiences[9].valeur)"><div class="experienceCellIndice eciL3  eciC3">9</div>{{ champExperiences[9].valeur }}</v-col>
                </v-row>
                </div>  
              </div>  
                <p  v-for="item in filterItems" class="content ql-editor pa-1"   v-html="item.content"  :key="item"> </p>
           
              </div> 
              </v-card-text> 
            
            </v-card>   
                <CommentaireSaisieComponent   :variant="crtVariant" v-on:updated='this.$emit("commentaireUpdated")' :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

        </v-container>
 </template>
 // propriete  destinée à recevoir le theme de l'experience

    <script>
      import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
      import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  components: {CommentaireSaisieComponent},
  props: ['champExperiences','showCom','etudeId','clientId','commentaires',"variant"],
  methods: {
    showComModal(itx, value){
      if(this.showCom){
        if(value=="11/2") value="2";
        if(value=="22/4") value="4";
        if(value=="33/6") value="6";
        if(value=="44/8") value="8";
        this.crtVariant = this.variant;
      this.item = itx;
      this.crtValue = value;
      this.showCommentaireSaisieComponent = this.showCom;
    }  },
  },computed:{
    filterItems() {    return this.commentaires?this.commentaires.filter(d=> d.outil==this.outil && d.variant == this.variant):[] },},
  data() {
    return {
      showCommentaireSaisieComponent: false,
      outil:"Champ des expériences",
      item:"1",
      crtVariant: "",
      crtValue:"1",
      planExpressionItem: ["cérébrale","physique","émotif","Intuitif"      ],
    }
  },

}
    </script>