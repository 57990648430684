
      <template>
      <v-sheet class="bg-grey-lighten-2">
        <v-row v-if="!AuthState.isAuthenticated">

          <v-col
            cols="2"
          
          ></v-col>
          <v-col
            cols="8"
      
          >
   
          <v-card class="rounded-xl pa-0 ma-2"   color="#05445E">
    
            <v-img
            src="./fond_vague_long.png"
            gradient="to right, #05445EDD, #05445EFF"
         
            height="50px"
            cover
          >
            <v-card-title  class="text-white text-body-1 "   style="color:black;"
           >Bienvenue</v-card-title>
          </v-img>
          
          <v-card-text style="background-color: white;"> 
        <v-row color="white">
          <v-col color="white"
            cols="4"  class="text-center"> 
            <v-avatar color="white"
            class="ma-3"
            rounded="0"
            size="125"
          >
            <v-img src="l.gif"></v-img>
          </v-avatar>
          <p class="text-center">Le logiciel de Numérologie Créative® 
            répond parfaitement à la méthode   
            créée par Colette Le Floch. 
            Il est accessible en ligne.
            <br/>
            <br/>Laurence Thiévin,
            <br/>Référente et formatrice de la Numérologie Créative® depuis 2014
      
        </p>
          
          </v-col>
            <v-col cols="8"  color="white" class="text-h5">
              Le logiciel professionnel de la Numérologie Créative® vous offre de nombreuses fonctionnalités :
              <div class="text-h6">
<br/>✓ Gérez vos clients : réaliser autant de thèmes que vous le souhaitez et assurer le suivi de vos clients 
<br/>✓ Réalisez vos documents : calcul et présentation graphique des thèmes complets, calendriers, plan de l’année, cycles, etc
<br/>✓ Préparez vos séances avec précision grâce au dictionnaire : pour chaque configuration de thème, retrouvez des propositions de lecture des nombres.
<br/>✓ Editez et/ou imprimez vos dossiers pdf 
<br/>✓ Abonnement mensuel ou annuel 
</div>
            </v-col>
        </v-row>
        </v-card-text>
              <v-card-actions   style="background-color: white;">
                <v-spacer></v-spacer>   <a href="https://www.numerologie-creative.com/" target="_blank"> <v-btn variant="elevated" rounded="xl" style="background-color:#05445E; color:white" > en savoir +</v-btn ></a>
                  <v-spacer></v-spacer>  <v-btn variant="elevated" rounded="xl" style="background-color:#05445E; color:white" v-if="!AuthState.isAuthenticated" @click="login">Connexion</v-btn >
                  <v-spacer></v-spacer>  <a :href="contact" target="_blank"> <v-btn variant="elevated" rounded="xl" style="background-color:#05445E; color:white" >Contact</v-btn ></a><v-spacer></v-spacer>   </v-card-actions>
                    </v-card>

          </v-col>
          <v-col
            cols="2"
          
          ></v-col>
       
        </v-row>


        <v-row v-if="AuthState.isAuthenticated">
          <v-col
          class="d-flex d-lg-none  " 
            cols="12"
          
          >
          <v-card class="rounded-lg pa-0 ma-2" width="100%" color="#05445E" v-if="store.authenticated && !store.user.bloque && store.user.isAuthorized" >
            <v-img
            src="./fond_vague_long.png"
            gradient="to right, #05445EDD, #05445EFF"
          
            height="50px"
            cover
          >
            <v-card-title class="text-white text-body-1" style="color:black;"
           >Mes Clients</v-card-title>
          
          </v-img>
            <v-card-text style="background-color: white;">   
              <v-btn  v-on:click="gotoCreateClient" >CREER UN CLIENT</v-btn>
              <v-btn  v-on:click="gotoClient" >ACCEDER A MON FICHIER CLIENT</v-btn>
           </v-card-text>
          
          </v-card>
          <v-card class="rounded-lg pa-0 ma-2"  color="#05445E" v-if="store.authenticated && !store.user.bloque && store.user.isAuthorized" >
            <v-img
            src="./fond_vague_long.png"
            gradient="to right, #05445EDD, #05445EFF"
          
            height="50px"
            cover
          >
            <v-card-title class="text-white text-body-1" style="color:black;"
           >Mode d'emploi</v-card-title>
          
          </v-img>
            <v-card-text style="background-color: white;">   
              <a :href="linkTutorial" target="_blank">  <v-btn >TUTORIEL</v-btn></a>
           </v-card-text>
          
          </v-card>
          </v-col>

<v-col
  cols="12"
  lg="8"
>

<v-card class="rounded-lg pa-0 ma-2"   color="#05445E" v-if="AuthState.isAuthenticated" >
    
  <v-img
  src="./fond_vague_long.png"
  gradient="to right, #05445EDD, #05445EFF"

  height="50px"
  cover
>
  <v-card-title  class="text-white text-body-1 "   style="color:black;"
 >Bienvenue</v-card-title>
</v-img>

<v-card-text style="background-color: white;"> 
<v-row color="white" v-if="AuthState.isAuthenticated">
<v-col color="white"  cols="4"  class="text-center" > 
  <v-avatar color="white"
  class="ma-3"
  rounded="0"
  size="125"
>
  <v-img src="l.gif"></v-img>
</v-avatar>
  <p class="text-center" > Référente de la Numérologie Créative® depuis 2014, 
    <br> j'ai coeur de vous accompagner dans votre pratique.
    <br> Laurence Thiévin</p>
</v-col>
<v-col cols="8"  color="white">
    <p class="text-h3">Bienvenue dans votre espace de Numérologie Créative®</p>
 
    <p class="text-h6">- Préparez vos séances en toute simplicité.
      <br> - Créez des documents personnalisés pour vos clients et intégrez des traductions de leurs nombres.
      <br>Pour vous guider pas à pas, nous avons préparé un tutoriel d'utilisation qui vous permettra de tirer le meilleur parti de toutes les fonctionnalités.</p>
</v-col>

</v-row>
</v-card-text>
    <v-card-actions   style="background-color: white;">
      <v-spacer></v-spacer>  <v-btn variant="elevated" rounded="xl" style="background-color:#05445E; color:white" v-if="!AuthState.isAuthenticated" @click="login">Connexion</v-btn >
        <v-spacer></v-spacer></v-card-actions>
</v-card>

  <v-card class="rounded-lg pa-0 ma-2" color="#05445E" v-if="AuthState.isAuthenticated" >

    <v-img
    src="./fond_vague_long.png"
    gradient="to right, #05445EDD, #05445EFF"
 
    height="50px"
    cover
  >
  



  <v-card-title class="text-white text-body-1" style="color:black;">Actualités  <v-btn   v-if="AuthState.isAuthenticated && !store.user.bloque && store.user.isAuthorized  && store.user.role=='Administrateur'"  style=" float:right;" variant="outlined" @click="saveActualite"> Enregistrer </v-btn></v-card-title>
</v-img>
  <v-card-text style="background-color: white;" >  
    <div style=" min-height:250px">    <QuillEditor  v-if="AuthState.isAuthenticated && !store.user.bloque && store.user.isAuthorized  && store.user.role=='Administrateur'"   theme="snow"  contentType="html"
    :options="editorOption" label="Actualités" v-model:content="actualite"/>
    
    <div v-else id="preview" class="content ql-editor" v-html="actualite"> </div></div>  </v-card-text>
</v-card>



</v-col>

<v-col
class="d-none d-lg-block" 
  cols="12"
  lg="4"
>
<v-card class="rounded-lg pa-0 ma-2"  color="#05445E" v-if="store.authenticated && !store.user.bloque && store.user.isAuthorized" >
  <v-img
  src="./fond_vague_long.png"
  gradient="to right, #05445EDD, #05445EFF"

  height="50px"
  cover
>
  <v-card-title class="text-white text-body-1" style="color:black;"
 >Mes Clients</v-card-title>

</v-img>
  <v-card-text style="background-color: white;">   
    <v-btn  v-on:click="gotoCreateClient" >CREER UN CLIENT</v-btn>
    <v-btn  v-on:click="gotoClient" >ACCEDER A MON FICHIER CLIENT</v-btn>
 </v-card-text>

</v-card>
<v-card class="rounded-lg pa-0 ma-2"  color="#05445E" v-if="store.authenticated && !store.user.bloque && store.user.isAuthorized" >
  <v-img
  src="./fond_vague_long.png"
  gradient="to right, #05445EDD, #05445EFF"

  height="50px"
  cover
>
  <v-card-title class="text-white text-body-1" style="color:black;"
 >Mode d'emploi</v-card-title>

</v-img>
  <v-card-text style="background-color: white;">   
    <a :href="linkTutorial" target="_blank">  <v-btn >TUTORIEL</v-btn></a>
 </v-card-text>

</v-card>
</v-col>
</v-row>
<v-dialog v-model="dialogConfirmUpdateAcceuil" max-width="500px">
  <v-card>
    <v-card-title class="text-h5">Message acceuil </v-card-title>
    <v-card-text>
      <p>Le message d'accueil a été enregistré</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogConfirmUpdateAcceuil=false">Valider</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
      </v-sheet>
   


     </template>
     <style>

</style>
<playground-resources lang="json">
  {
    "css": ["https://use.fontawesome.com/releases/v5.1.0/css/all.css"]
  }
</playground-resources>
<script setup>
import { store } from '../store.js'
import messageService from '../services/messageService'
import { useAuth0, AuthState } from "../auth";
import router from "@/router";
import { QuillEditor,Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import configService from '../services/configService';


  Quill.register("modules/imageCompressor", imageCompressor);

  const {  login, initAuth } = useAuth0(AuthState);
  initAuth();
</script>
<script>


export default {
mounted() {

  messageService.getAccueil().then((response) => {
    this.actualite = response.data;
  });
  messageService.getLinkTutorial().then((response) => {

    this.linkTutorial = response.data;
  });
  messageService.getContact().then((response) => {

this.contact = response.data;
});

  
},
  components: {
    QuillEditor
  },
  name: 'HomePage',
  methods:{
    saveActualite(){
      messageService.saveAccueil(this.actualite).then(() => {
        this.dialogConfirmUpdateAcceuil =true
      });
    },
    gotoCreateClient(){
      router.push({ name: 'clientCreate'})
    },
    gotoClient(){
            router.push({ name: "clients" });

     },
  },
  data: () => ({
    linkTutorial:"",
    dialogConfirmUpdateAcceuil: false,
    editorOption: configService.getQuillConfig(),
  bienvenue:"un message de bienvenu",
  actualite:"des actualités",
    contact:""
    }),
  props: {
    msg: String
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
