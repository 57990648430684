<template>
  <v-container>
    <v-card variant="flat">
      <v-card-title class="themeTitle">Spirale d'involution</v-card-title>
      <v-card-text class="justify-center">
        <div>
          <div
            style="
              shape-outside: content-box;
                padding: 0px;
              shape-margin: 30px;
              width: 380px;
              height: 320px;
              float: left;
            "
          >
            <div  style="padding: 0px;">
              <img
                style="
                  position: absolute;
                  top: 50px;
                  text-align: center;
                  left: 60px;
                  height: 300px;
                "
                :src="'./spirale_' + doubleSpiral[5].couleur + '_g.svg'"
              />
              <div  style="padding: 0px;"> 
                <div                  :class="[doubleSpiral[2].valeur.length>2?'spiralCellMaitre':'spiralCell','rounded-lg',showCom ? 'linklookalike' : '',doubleSpiral[2].couleur,]"
                  style="
                  justify-content: center;
                   
                    top: 50px;
                    left: 110px;
                  "
                  v-on:click="showComModal('2', doubleSpiral[2].valeur)"
                >
                  {{ doubleSpiral[2].valeur }}
                </div>
                <div
                  :class="[
                    doubleSpiral[1].valeur.length>2?'spiralCellMaitre':'spiralCell',
                    'rounded-lg',
                    showCom ? 'linklookalike' : '',
                    doubleSpiral[1].couleur,
                  ]"
                  style="
                  justify-content: center;
                    top: 100px;
                    left: 100px;
                  "
                  v-on:click="showComModal('1', doubleSpiral[1].valeur)"
                >
                  {{ doubleSpiral[1].valeur }}
                </div>
                <div
                  :class="[
                    doubleSpiral[3].valeur.length>2?'spiralCellMaitre':'spiralCell',
                    'rounded-lg',
                    showCom ? 'linklookalike' : '',
                    doubleSpiral[3].couleur,
                  ]"
                  style="justify-content: end; top: 195px; left: -28px"
                  v-on:click="showComModal('3', doubleSpiral[3].valeur)"
                >
                  {{ doubleSpiral[3].valeur }}
                </div>
                <div
                  :class="[
                    doubleSpiral[4].valeur.length>2?'spiralCellMaitre':'spiralCell',
                    'rounded-lg',
                    showCom ? 'linklookalike' : '',
                    doubleSpiral[4].couleur,
                  ]"
                  style="
                  justify-content: start;
                    top: 210px;
                    left: 280px;
                  "
                  v-on:click="showComModal('4', doubleSpiral[4].valeur)"
                >
                  {{ doubleSpiral[4].valeur }}
                </div>
                <div
                  :class="[
                    doubleSpiral[5].valeur.length>2?'spiralCellMaitre':'spiralCell',
                    'rounded-lg',
                    showCom ? 'linklookalike' : '',
                    doubleSpiral[5].couleur,
                  ]"
                  style="
                  justify-content: end;
                    top: 280px;
                    left: -30px;
                  "
                  v-on:click="showComModal('5', doubleSpiral[5].valeur)"
                >
                  {{ doubleSpiral[5].valeur }}
                </div>

                <CommentaireSaisieComponent v-on:updated='this.$emit("commentaireUpdated")'
                  :show="showCommentaireSaisieComponent"
                  :etudeId="etudeId"
                  :clientId="clientId"
                  :outil="outil"
                  :item="item"
                  :variant="crtVariant"
                  :value="crtValue"
                  v-on:closed="showCommentaireSaisieComponent = false"
                ></CommentaireSaisieComponent>
              </div>
            </div>
          </div>
          <div v-if="showComment"><p
            v-for="item in filterItems"
            class="content ql-editor pa-1"
            v-html="item.content"
            :key="item"
          ></p></div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
// propriete destinée à recevoir le theme de l'experience

<script>
import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  props: ["doubleSpiral", "showCom", "etudeId", "clientId", "commentaires","showComment","variant"],
  components: { CommentaireSaisieComponent },
  computed:{
    filterItems() {    return this.commentaires?this.commentaires.filter(d=> d.outil==this.outil && d.variant == this.variant):[] },},
  data() {
    return {
      showCommentaireSaisieComponent: false,
      outil: "Spirale d'involution",
      item: "1",
      crtValue: "1",
      crtVariant: "",
    };
  },
  methods: {
    showComModal(itx, value) {
      if (this.showCom) {
        this.item = itx;
        this.crtValue = value;
        this.crtVariant = this.variant;
        this.showCommentaireSaisieComponent = this.showCom;
      }
    },
  },

};
</script>
